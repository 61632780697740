import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { Heading, Container, Section } from 'react-bulma-components';

const TermsAndConditions = () => {
  return (
    <>
      <SEO title="Köpvillkor | Wallang" />
      <Container>
        <Section className="info-section">
          <Heading className="has-text-centered">Köpvillkor</Heading>

          <h2 className="info-section__header">Allmänt</h2>
          <p>Wallang säljer produkter med leverans inom Sverige. Beställningar tas enbart emot via wallang.se.</p>
          <p>
            För att du skall känna dig trygg i ditt köp är du alltid välkommen att kontakta vår kundservice om du har frågor om din beställning. Efter att ha beställt från
            wallang.se förbinder du dig att bevaka din e-mailadress då all kommunikation rörande din order sker via e-mail.
          </p>
          <p>
            Wallang reserverar sig för slutförsäljning, förseningar, tekniska problem och felaktig artikelinformation. Wallang förbehåller sig rätten till prisändringar utan
            föregående avisering. Därtill förbehåller vi oss rätten att stryka en beställning om särskilda skäl föreligger.
          </p>

          <h2 className="info-section__header">Priser och betalning</h2>
          <p>I samarbete med Klarna erbjuder vi fakturabetalning, delbetalning, kontokortsbetalning samt direktbetalning.</p>
          <p>Fakturan skickas ut från Klarna via mail i samband med att ordern skickas från oss. Fakturan har en förfallotid på 14 dagar. </p>
          <p>
            Priserna på våra produkter anges i svenska kronor inklusive moms. Momsen är 25% för alla våra produkter. Vi förbehåller oss rätten att ändra priser utan föregående
            avisering. Priset som var aktuellt vid beställningstillfället är det som gäller oavsett om priset går upp eller ned efteråt.
          </p>
          <p>I kassan kan man se det totala priset. I kassan hittar du också fullständiga frakt- och betalningsvillkor.</p>

          <h2 className="info-section__header">Fraktsätt och Leverans</h2>
          <p>Vi skickar alla leveranser vårt kostnadsfria leveranssätt som skickas med PostNord till din dörr eller brevlåda. Leveranstiden är normalt 2-4 vardagar.</p>

          <h2 className="info-section__header">Ångerrätt och retur</h2>
          <p>
            Om du vill ta bort en vara eller hela din beställning så kontakta <a href="mailto:info@wallang.se">info@wallang.se</a>, uppge ordernummer.
          </p>
          <p>Som kund har du alltid 14 dagars ångerrätt (räknat från att du mottagit produkten) enligt lagen om distansavtal och avtal utanför affärslokal.</p>
          <p>
            Returnerar du en oanvänd vara med eventuellt tillhörande etiketter och förpackning inom 14 dagar återbetalas hela beloppet. Om produktens kvalitet har påverkats till
            följd av att du som kund har hanterat den mer än vad som är nödvändigt för att undersöka dess egenskaper eller funktioner, kommer avdrag på återbetalningen motsvarande
            varans värdeminskning att göras. Det är viktigt att du förpackar varan med ett yttre emballage så att originalförpackningen är skyddad. Klistra aldrig returetiketten
            direkt på originalförpackningen.
          </p>
          <p>
            Vi tar inte ut någon returavgift när du returnerar men du betalar själv för frakten tillbaka enligt gällande portotabell. För ej uthämtade brev eller paket debiterar vi
            dig för våra kostnader för frakt, returfrakt, administration och hantering, för närvarande 149 kr.
          </p>
          <h3>
            <strong>Returadress</strong>
          </h3>
          <p>
            Wallang HB <br />
            C/o Box 934 <br />
            Östersundsgatan 7 <br />
            162 74 VÄLLINGBY
          </p>

          <h2 className="info-section__header">Reklamation</h2>
          <p>
            Om en vara avviker från vad som följer av avtalet mellan dig och Wallang eller på annat sätt inte motsvarar den beskrivning av varan som Wallang har givit, kan varan
            reklameras. Endast ursprungliga fel omfattas av reklamationsrätten, reklamationsrätten omfattar t ex inte fel orsakade av normalt slitage. Reklamation till Wallang ska
            göras inom skälig tid efter det att felet upptäckts, dock senast inom tre år. Wallang förbehåller sig rätten att i första hand avhjälpa felet, i andra hand omleverans
            av varan och i sista hand återköp.
          </p>

          <h2 className="info-section__header">Personuppgifter</h2>
          <p>
            Det är viktigt för oss att du som kund känner dig trygg när du handlar hos oss. <Link to="/personuppgifter">Här</Link> kan du läsa om hur vi hanterar och skyddar
            personuppgifter.
          </p>

          <h2 className="info-section__header">Cookies</h2>
          <p>
            En cookie är en textfil som lagras i din webbläsare. Cookies används för att lagra information. Vi använder oss av cookies för att identifiera ifrån vilken annons eller
            sökmotor som besökaren hänvisats från samt för att hantera data som skickas till Google Analytics.
          </p>
        </Section>
      </Container>
    </>
  )
}

export default TermsAndConditions;